<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('tpm_report.circular_publication_report') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col sm="4">
            <b-form-group
              label-for="fiscal_year"
            >
              <template v-slot:label>
                {{ $t('elearning_config.fiscal_year') }}
              </template>
              <b-form-select
                plain
                id="fiscal_year_id"
                :options="fiscalYearList"
                v-model="search.fiscal_year_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group
              label-for="org_id">
              <template v-slot:label>
                {{ $t('elearning_config.organization') }}
              </template>
              <b-form-select
                plain
                id="org_id"
                :options="orgList"
                v-model="search.org_id"
              >
              <template v-slot:first>
                <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group
              label-for="circular_memo_no">
              <template v-slot:label>
                {{ $t('elearning_iabm.circular_memo_no') }}
              </template>
              <b-form-select
                plain
                id="circular_memo_no"
                :options="circularMemoList"
                v-model="search.circular_memo_no"
              >
              <template v-slot:first>
                <b-form-select-option value="">{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group
              label-for="training_title_id"
            >
              <template v-slot:label>
                {{ $t('elearning_config.training_title') }}
              </template>
              <b-form-select
                plain
                id="training_title_id"
                :options="trainingTitleList"
                v-model="search.training_title_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="4" class="pt-3">
            <b-button size="sm" variant="primary" @click="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>
    <b-row>
        <b-col md="12" v-if="showData">
          <b-overlay>
            <b-row>
              <b-col md="12">
                <body-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">
                      {{ $t('tpm_report.circular_publication_report') }} {{ $t('globalTrans.list') }}
                    </h4>
                  </template>
                  <template v-slot:headerAction>
                    <b-button variant="primary" @click="pdfExport()" size="sm" class="float-right">
                        {{  $t('globalTrans.export_pdf') }}
                    </b-button>
                  </template>
                  <template v-slot:body>
                    <b-overlay :show="customloading">
                      <list-report-head :base-url="baseUrl" uri="/config/report-head/detail" :org-id="search.org_id ? search.org_id : 10">
                        <template v-slot:projectNameSlot>
                          {{ }}
                        </template>
                        {{ $t('tpm_report.circular_publication_report') }}
                      </list-report-head>
                      <b-row>
                        <b-col md="3">
                          <span>{{ $t('elearning_config.fiscal_year') }}</span>:
                          <strong>{{ search.fiscal_year_id ? ($i18n.locale === 'en' ? searchHeaderData.fiscal_year_en : searchHeaderData.fiscal_year_bn) : $t('globalTrans.all') }}</strong>
                        </b-col>
                        <b-col md="3" class="text-center">
                          <span>{{ $t('elearning_config.organization') }}</span>:
                          <strong>{{ search.org_id ? ($i18n.locale === 'en' ? searchHeaderData.org_en : searchHeaderData.org_bn) : $t('globalTrans.all') }}</strong>
                        </b-col>
                        <b-col md="3" class="text-right">
                          <span>{{ $t('elearning_iabm.circular_memo_no') }}</span>:
                          <strong>{{ search.circular_memo_no ? search.circular_memo_no : $t('globalTrans.all') }}</strong>
                        </b-col>
                        <b-col md="3" class="text-right">
                          <span>{{ $t('elearning_config.training_title') }}</span>:
                          <strong>{{ search.training_title_id ? ($i18n.locale === 'en' ? searchHeaderData.training_title_en : searchHeaderData.training_title_bn) : $t('globalTrans.all') }}</strong>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="12" sm="12">
                          <table style="width:100%;">
                            <thead>
                              <tr>
                                <th>{{ $t('globalTrans.sl_no') }}</th>
                                <th>{{ $t('elearning_config.fiscal_year') }}</th>
                                <th>{{ $t('elearning_config.organization') }}</th>
                                <th>{{ $t('elearning_iabm.circular_memo_no') }}</th>
                                <th>{{ $t('elearning_config.training_title') }}</th>
                                <th>{{ $t('elearning_iabm.training_start_date') }}</th>
                                <th>{{ $t('elearning_iabm.training_end_date') }}</th>
                                <th>{{ $t('globalTrans.action') }}</th>
                              </tr>
                            </thead>
                            <tbody v-if="finalList.length">
                              <tr v-for="(item, index) in finalList" :key="index">
                                <td>{{ $n(index + 1, { useGrouping: false }) }}</td>
                                <td>{{ getFiscalYear(item.fiscal_year_id) }}</td>
                                <td>{{ getOrg(item.org_id) }}</td>
                                <td>{{ item.circular_memo_no }}</td>
                                <td>{{ getTrainingTitle(item.training_title_id) }}</td>
                                <td>{{ item.training_start_date | dateFormat }}</td>
                                <td>{{ item.training_end_date | dateFormat }}</td>
                                <td>
                                  <b-button v-b-modal.modal-5 variant="iq-bg-success mr-1" size="sm" @click="detailData(item)" class="moc-action-btn moc-view-btn" :title="$t('globalTrans.view')"><i class="ri-eye-line"></i></b-button>                        </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td colspan="7" class="text-center"> {{ $t('fair_report.not_found') }} </td>
                              </tr>
                            </tbody>
                          </table>
                        </b-col>
                      </b-row>
                    </b-overlay>
                  </template>
                </body-card>
              </b-col>
            </b-row>
          </b-overlay>
        </b-col>
      </b-row>
    <body-card>
      <!-- table section start -->
      <!-- <pre>{{ finalList.length }}</pre>
      <pre>{{ finalList }}</pre> -->
      <!-- table section end -->
    </body-card>
    <b-modal id="modal-5" size="lg" hide-footer :title="$t('elearning_iabm.circular_info')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <!-- <b-button @click="pdfExportDetails" class="ml-4 btn-success water-test-pdf-button">
            {{  $t('globalTrans.export_pdf') }}
        </b-button> -->
        <Details :item="item" :key="id" ref="details"/>
    </b-modal>
  </div>
</template>
<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { circularPublicationReportList, circularPublicationList, orgByFiscalYear, circularMemoByOrgId, circularData } from '../../api/routes'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import Details from './Details'
import ModalBaseMasterList from '@/mixins/list'
export default {
  mixins: [ModalBaseMasterList],
  components: {
    ListReportHead, Details
  },
  data () {
    return {
      baseUrl: trainingElearningServiceBaseUrl,
      sortBy: '',
      customloading: false,
      isResult: false,
      showData: false,
      finalList: [],
      circularList: [],
      organizationList: [],
      circularMemoList: [],
      titleList: [],
      search: {
        fiscal_year_id: 0,
        org_id: 0,
        circular_memo_no: '',
        training_title_id: 0,
        limit: 20
      },
      searchHeaderData: {
        fiscal_year_en: '',
        fiscal_year_bn: '',
        org_en: '',
        org_bn: '',
        circular_memo_no: '',
        training_title_en: '',
        training_title_bn: ''
      },
      id: 0,
      item: '',
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '9%' } },
        { labels: 'elearning_config.fiscal_year', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '20%' } },
        { labels: 'elearning_config.organization', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '20%' } },
        { labels: 'elearning_iabm.circular_memo_no', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '20%' } },
        { labels: 'elearning_config.training_title', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '20%' } },
        { labels: 'elearning_iabm.training_start_date', class: 'text-center', show: '1', order: 6 },
        { labels: 'elearning_iabm.training_end_date', class: 'text-center', show: '1', order: 7 },
        { labels: 'globalTrans.action', class: 'text-center', show: '1', order: 8 }
      ]
    }
  },
  created () {
    this.getCircularMemo()
    this.getOrgByFisalYear()
    // this.getCircularList()
    // this.search.fiscal_year_id = this.$store.state.currentFiscalYearId
    // this.loadData()
  },
  watch: {
    'search.fiscal_year_id': function (newValue) {
          this.getOrgByFisalYear(newValue)
      },
    'search.org_id': function (newValue) {
          this.getCircularMemo(newValue)
      },
    'search.circular_memo_no': function (newValue) {
        this.getCircularNo()
      }
  },
  computed: {
    trainingTitleList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
    },
    currentLocale () {
      return this.$i18n.locale
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(el => el.status === 1)
    },
    orgList () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(el => el.status === 1)
    },
    listData: function () {
      const tmpData = this.$store.state.list
      return JSON.parse(JSON.stringify(tmpData))
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'fiscal_year_id' },
          { key: 'org_id' },
          { key: 'circular_memo_no' },
          { key: 'training_title_id' },
          { key: 'training_start_date' },
          { key: 'training_end_date' },
          { key: 'action' }
        ]
      } else {
        keys = [
        { key: 'serial' },
          { key: 'fiscal_year_id' },
          { key: 'org_id' },
          { key: 'circular_memo_no' },
          { key: 'training_title_id' },
          { key: 'training_start_date' },
          { key: 'training_end_date' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  methods: {
    async getCircularNo () {
      this.load = true
      const params = {
        circular_memo_no: this.search.circular_memo_no,
        table: 'iab_circular_publications'
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularData, params)
      if (!result.success) {
        this.search.training_title_id = 0
      } else {
        this.lcErrorMsg = ''
        const trData = result.data
        this.search.training_title_id = trData.training_title_id
        this.load = false
      }
      this.load = false
    },
    detailData (item) {
        this.item = item
    },
    async getOrgByFisalYear (ficalYearId) {
        if (ficalYearId) {
            this.customloading = true
            const params = {
              fiscal_year_id: this.search.fiscal_year_id
            }
            const result = await RestApi.getData(trainingElearningServiceBaseUrl, orgByFiscalYear, params)
            if (!result.success) {
            } else {
              const listObject = result.data
              const tmpList = listObject.map((obj) => {
                const orgObj = this.orgList.find(el => el.value === parseInt(obj.org_id))
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.org_id, text: orgObj.text_bn }
                } else {
                    return { value: obj.org_id, text: orgObj.text_en }
                }
                })
                this.organizationList = tmpList
            }
            this.customloading = false
        } else {
          this.organizationList = this.orgList
        }
    },
    async getCircularMemo (orgId) {
        if (orgId && this.search.fiscal_year_id) {
            this.customloading = true
            const params = {
              fiscal_year_id: this.search.fiscal_year_id,
              org_id: orgId
            }
            const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularMemoByOrgId, params)
            if (!result.success) {
            } else {
              const listObject = result.data
              this.circularMemoList = listObject.map((obj) => {
                  if (this.$i18n.locale === 'bn') {
                      return { value: obj.circular_memo_no, text: obj.circular_memo_no }
                  } else {
                      return { value: obj.circular_memo_no, text: obj.circular_memo_no }
                  }
                })
              // this.titleList = listObject.map((obj) => {
              //   const title = this.trainingTitleList.find(el => el.value === parseInt(obj.training_title_id))
              //   if (this.$i18n.locale === 'bn') {
              //         return { value: title.value, text: title.text_bn }
              //     } else {
              //         return { value: title.value, text: title.text_en }
              //     }
              //   })
            }
            this.customloading = false
        } else if (orgId) {
          const params = {
            org_id: orgId
            }
          const circularMemo = await RestApi.getData(trainingElearningServiceBaseUrl, '/report/circular-publication/circular', params)
              if (!circularMemo.success) {
            } else {
                const listObject = circularMemo.data
                const tmpList = listObject.map((obj, index) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.circular_memo_no, text: obj.circular_memo_no }
                } else {
                    return { value: obj.circular_memo_no, text: obj.circular_memo_no }
                }
              })
              this.circularMemoList = tmpList
              // this.titleList = listObject.map((obj) => {
              // const title = this.trainingTitleList.find(el => el.value === parseInt(obj.training_title_id))
              // if (this.$i18n.locale === 'bn') {
              //       return { value: title.value, text: title.text_bn }
              //   } else {
              //       return { value: title.value, text: title.text_en }
              //   }
              // })
            }
        } else {
          const circularMemo = await RestApi.getData(trainingElearningServiceBaseUrl, '/report/circular-publication/circular-memo')
              if (!circularMemo.success) {
            } else {
                const listObject = circularMemo.data
                const tmpList = listObject.map((obj, index) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.circular_memo_no, text: obj.circular_memo_no }
                } else {
                    return { value: obj.circular_memo_no, text: obj.circular_memo_no }
                }
              })
              this.circularMemoList = tmpList
              // this.titleList = listObject.map((obj) => {
              // const title = this.trainingTitleList.find(el => el.value === parseInt(obj.training_title_id))
              // if (this.$i18n.locale === 'bn') {
              //       return { value: title.value, text: title.text_bn }
              //   } else {
              //       return { value: title.value, text: title.text_en }
              //   }
              // })
            }
        }
    },
    getFiscalYear (id) {
      const countryObj = this.fiscalYearList.find(el => el.value === parseInt(id))
      if (countryObj !== 'undefined') {
        return this.currentLocale === 'bn' ? countryObj.text_bn : countryObj.text_en
      }
    },
    getOrg (id) {
      const countryObj = this.orgList.find(el => el.value === parseInt(id))
      if (countryObj !== 'undefined') {
        return this.currentLocale === 'bn' ? countryObj.text_bn : countryObj.text_en
      }
    },
    getTrainingTitle (id) {
      const countryObj = this.trainingTitleList.find(el => el.value === parseInt(id))
      if (countryObj !== 'undefined') {
        return this.currentLocale === 'bn' ? countryObj.text_bn : countryObj.text_en
      }
    },
    async getCircularList () {
        this.customloading = true
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublicationList + '/' + 'tpm_training_calendar' + '/' + 1)
        if (!result.success) {
        } else {
            const listObject = result.data
            const tmpList = listObject.map((obj, index) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.circular_memo_no, text: obj.circular_memo_no }
            } else {
                return { value: obj.circular_memo_no, text: obj.circular_memo_no }
            }
          })
          this.circularList = tmpList
        }
        this.customloading = false
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      this.customloading = true
      this.showData = true
      if (this.search.fiscal_year_id) {
        const fiscalYear = this.fiscalYearList.find(obj => obj.value === this.search.fiscal_year_id)
        const customItem = {
          fiscal_year_en: fiscalYear?.text_en,
          fiscal_year_bn: fiscalYear?.text_bn
        }
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, customItem)
      }
      if (this.search.org_id) {
        const orgObj = this.orgList.find(obj => obj.value === this.search.org_id)
        const orgData = {
          org_en: orgObj?.text_en,
          org_bn: orgObj?.text_bn
        }
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, orgData)
      }
      if (this.search.circular_memo_no) {
        const cicularObj = this.circularList.find(obj => obj.value === this.search.circular_memo_no)
        const circularData = {
          circular_memo_no: cicularObj?.text
        }
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, circularData)
      }
      if (this.search.training_title_id) {
        const trainingTitleObj = this.trainingTitleList.find(obj => obj.value === this.search.training_title_id)
        const trainingTitleData = {
          training_title_en: trainingTitleObj?.text_en,
          training_title_bn: trainingTitleObj?.text_bn
        }
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, trainingTitleData)
      }

      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      // this.$store.dispatch('mutateCommonProperties', { customloading: true, listReload: false })
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublicationReportList, params)
      if (result.success) {
        this.finalList = result.data
        this.customloading = false
        this.isResult = true
      }
    },
    async pdfExport () {
      this.customloading = true
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, fiscal_year_id: this.search.fiscal_year_id, org_id: this.search.org_id, circular_memo_no: this.search.circular_memo_no, training_title_id: this.search.training_title_id })
      const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, circularPublicationReportList, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.customloading = false
    }
  }
}
</script>
<style scoped>
  table, th, td {
    border: 1px solid black;
    padding:5px;
  }
</style>
