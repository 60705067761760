<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
           <body-card>
            <template>
                <b-overlay :show="loading">
                    <b-row>
                    <b-col>
                        <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                            <div class="titleDiv">
                                <span class="text-center text-dark reportTitle" style="font-weight:bold;">{{ $t('elearning_iabm.circular_info') }}</span><br>
                            </div>
                        </list-report-head>
                    </b-col>
                    </b-row>
                    <b-col lg="12">
                        <b-row>
                        <b-col lg="12" sm="12" class="p-0">
                            <b-table-simple bordered>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('externalTraining.circular_memo_no') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left bg-light">{{ circular.circular_memo_no }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left">{{ $t('elearning_iabm.nominated_organization_type') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left">{{ getOrgTypeList(JSON.parse(circular.org_type_id)) }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('elearning_iabm.nominated_organization') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left bg-light">{{ getOrgList(JSON.parse(circular.nominated_org_id)) }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left">{{ $t('elearning_config.training_type') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left">{{getTrainingType(circular.training_type_id)}}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left">{{ $t('elearning_config.training_title') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left">{{getTrainingTitle(circular.training_title_id)}}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('teaGardenPduService.application_deadline') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left bg-light">{{ circular.application_deadline | dateFormat }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('elearning_iabm.training_start_date') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left bg-light">{{ circular.training_start_date | dateFormat }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left">{{ $t('elearning_iabm.training_end_date') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left">{{ circular.training_end_date | dateFormat }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left">{{ $t('ditfTradeFairManages.circular_type') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left">{{ getCircularTypeList(circular.circular_type) }}</b-td>
                                </b-tr>
                                <b-tr v-if="circular.circular_type === 1">
                                    <b-th style="width:35%;" scope="row" class="text-left">{{ $t('elearning_iabm.registration_fee') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left">{{ $n(circular.registration_fee) }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('globalTrans.description') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left bg-light">{{ circular.description }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('elearning_iabm.target_audience') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left bg-light">{{ circular.target_audience }}</b-td>
                                </b-tr>
                            </b-table-simple>
                            <b-table-simple bordered v-if="circular.upload_guidelines">
                              <thead>
                                <tr>
                                  <th style="width:20%">{{ $t('globalTrans.sl_no') }}</th>
                                  <th style="width:40%">{{ $t('globalTrans.document') }} {{ $t('globalTrans.name') }}</th>
                                  <th style="width:40%">{{ $t('elearning_iabm.upload_guidelines') }}</th>
                                </tr>
                              </thead>
                              <b-tbody>
                                <b-tr v-for="(item, index) in circular.upload_guidelines" :key="index">
                                    <b-td>
                                      <slot v-if="item.attachment_name">{{ $n(index + 1) }}</slot>
                                    </b-td>
                                    <b-td>
                                      {{ item.attachment_name }}
                                    </b-td>
                                    <b-td>
                                      <a v-if="item.attachment" target = '_blank' :href="trainingElearningServiceBaseUrl + 'download-attachment?file=uploads/' + item.attachment" download><i class="ri-file-download-line"></i>{{$t('globalTrans.view_download')}}</a>
                                    </b-td>
                                </b-tr>
                              </b-tbody>
                            </b-table-simple>
                        </b-col>
                        </b-row>
                    </b-col>
              </b-overlay>
            </template>
          </body-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<style scoped>
.table-form th, td{
  padding: 3px !important;
  margin: 1px !important;
  vertical-align: middle !important;
  font-size: 15px;
}
</style>
<script>
import ExportPdf from './export_pdf_details'
import { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'

export default {
    name: 'FormLayout',
    components: {
      ListReportHead
    },
    props: ['item'],
    created () {
      this.circular = this.item
    },
    data () {
        return {
          circular: [],
          slOffset: 1,
          trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
          orgTypeList: [
            {
              value: 1,
              text: this.$i18n.locale === 'bn' ? 'এমওসি' : 'MOC',
              text_en: 'MOC',
              text_bn: 'এমওসি'
            },
            {
              value: 2,
              text: this.$i18n.locale === 'bn' ? 'ব্যক্তিগত' : 'Private',
              text_en: 'Private',
              text_bn: 'ব্যক্তিগত'
            }
          ],
          circularType: [
            {
              value: 1,
              text: 'With Fee',
              text_en: 'With Fee',
              text_bn: 'ফি সহ'
            },
            {
              value: 2,
              text: 'Without Fee',
              text_en: 'Without Fee',
              text_bn: 'ফি ছাড়া'
            }
          ]
        }
    },
    computed: {
      currentLocale () {
        return this.$i18n.locale
      },
        loading () {
        return this.$store.state.commonObj.loading
      },
      trainingTitleList () {
        return this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(el => el.status === 1)
      }
    },
    methods: {
    getOrgTypeList (data) {
      var orgTypes = []
        if (Array.isArray(data)) {
          data.forEach(arrItem => {
          const item = this.orgTypeList.find(item => item.value === arrItem)
          if (item && this.$i18n.locale === 'bn') {
            orgTypes.push(item.text_bn)
          } else if (item && this.$i18n.locale === 'en') {
            orgTypes.push(item.text_en)
          }
          })
      }
      return orgTypes.join(', ')
    },
    getOrgList (data) {
        var orgTypes = []
        if (Array.isArray(data)) {
          data.forEach(arrItem => {
          const item = this.$store.state.TrainingElearning.commonObj.nominatedOrgList.find(item => item.value === arrItem)
          if (item && this.$i18n.locale === 'bn') {
            orgTypes.push(item.text_bn)
          } else if (item && this.$i18n.locale === 'en') {
            orgTypes.push(item.text_en)
          }
          })
      }
      return orgTypes.join(', ')
    },
      getCircularTypeList (circularType) {
          const orgId = this.circularType.find(obj => obj.value === circularType)
          return this.$i18n.locale === 'en' ? ' ' + orgId.text_en : ' ' + orgId.text_bn
      },
      getTrainingTitle (id) {
        const countryObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(el => el.value === parseInt(id))
          if (countryObj !== 'undefined') {
            return this.currentLocale === 'bn' ? countryObj.text_bn : countryObj.text_en
          }
      },
      getTrainingType (id) {
        const countryObj = this.trainingTitleList.find(el => el.value === parseInt(id))
          if (countryObj !== 'undefined') {
            return this.currentLocale === 'bn' ? countryObj.text_bn : countryObj.text_en
          }
      },
      pdfExport () {
        const reportTitle = this.$t('elearning_iabm.circular_info')
        ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.circular, this)
      }
    }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  /* .reportTitle {
    font-weight: bold;
    border: 1px solid;
    padding: 8px 15px 8px 15px;
    border-radius: 11px;
  } */
  /* .titleDiv {
    margin-top: 2rem;
    font-size: 18px;
    margin-bottom: 20px;
  } */
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
